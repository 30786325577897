import { setError } from "../../redux/actions";

export const updateObject = (oldObject, updatedProps) => {
  return {
    ...oldObject,
    ...updatedProps,
  };
};

export const formatNumber = (num) => {
  return "IDR " + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const stripMR = (val) => {
  let arr = val.split("");
  let text = "";
  arr.map((item, index) => {
    if (index > 0 && index % 2 === 0) {
      text += "-";
    }
    return (text += item);
  });
  return text;
};

export const parseToString = (val) => {
  return Number.isInteger(val) ? val.toString() : val;
};

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const errorHandler = (dispatch, err) => {
  if (err.toString().includes("Network Error")) {
    dispatch(setError("connection"));
  } else {
    dispatch(setError("system"));
  }
};
