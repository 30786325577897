import axios from "axios";
import { BASE_URL } from "../../helpers/common/config";
import { errorHandler } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";
import { setLoading } from "./";
import { logout } from "./auth";
export const setFormVaksin = (payload) => {
  return {
    type: actionTypes.SET_VAKSIN,
    data: {
      key: payload.key,
      value: payload.value,
    },
  };
};

export const resetVaksin = () => {
  return {
    type: actionTypes.RESET_VAKSIN,
  };
};

export const setIdentitasAuto = (payload) => {
  return {
    type: actionTypes.SET_IDENTITAS_AUTO,
    data: payload.data,
  };
};

export const resetAppointment = () => {
  return {
    type: actionTypes.RESET_APPOINTMENT,
  };
};

export const setAppointment = (payload) => {
  return {
    type: actionTypes.SET_APPOINTMENT,
    data: payload,
  };
};
const setHistoryAppointment = (payload) => {
  return {
    type: actionTypes.SET_HISTORY_APPOINTMENT,
    data: payload.data,
  };
};
const setHistoryAppDetail = (payload) => {
  return {
    type: actionTypes.SET_HISTORY_APPOINTMENT_DETAIL,
    data: payload.data,
  };
};

export const getIdentitas = (nik) => {
  const url = BASE_URL + "/api/master/get-nik";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        params: {
          nik: nik,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setIdentitasAuto(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const registerVaksin = (body, action) => {
  const url = BASE_URL + "/api/layanan/daftar-vaksin";
  // localStorage.setItem("daftarBaru", JSON.stringify(body));
  // return action && action.success && action.success();

  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body)
      .then((payload) => {
        return action && action.success && action.success();
      })
      .catch((err) => {
        return (
          action &&
          action.success &&
          action.failed(
            err.response && err.response.data
              ? err.response.data.message
              : "Ada Kesalahan"
          )
        );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const makeAppointment = (body, action) => {
  const url = BASE_URL + "/api/layanan/ambil-antrian";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        action && action.success(payload.data.data.rkun_id);
        // return dispatch(setAuth(payload.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return dispatch(
            logout(
              action &&
                action.failed(
                  err.response.data
                    ? err.response.data.message
                    : "Ada Kesalahan"
                )
            )
          );
        }
        action &&
          action.failed(
            err.response.data ? err.response.data.message : "Ada Kesalahan"
          );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const getHistoryAppointment = (body, action) => {
  const url = BASE_URL + "/api/antrian/history";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        // action && action.success();
        return dispatch(setHistoryAppointment(payload.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return dispatch(logout(action && action()));
        }
        // action &&
        //   action.failed(
        //     err.response.data ? err.response.data.message : "Ada Kesalahan"
        //   );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const getHistoryAppDetail = (body, action) => {
  const url = BASE_URL + "/api/antrian/history/detail";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        params: body,
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        action && action();
        return dispatch(setHistoryAppDetail(payload.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return dispatch(logout(action && action()));
        }
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const batalAppointment = (body, action) => {
  const url = BASE_URL + "/api/antrian/cancel";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        action && action.success();
        // return dispatch(setAuth(payload.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return dispatch(
            logout(
              action &&
                action.failed(
                  err.response.data
                    ? err.response.data.message
                    : "Ada Kesalahan"
                )
            )
          );
        }
        action &&
          action.failed(
            err.response.data ? err.response.data.message : "Ada Kesalahan"
          );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};
