import React from "react";
import { Routes, Route } from "react-router-dom";
import RouteContainer from "./pages/RouteContainer";
const DocterListPage = React.lazy(() => import("./pages/DocterListPage"));
const DetailDoctor = React.lazy(() => import("./pages/DetailDoctor"));
const Appointment = React.lazy(() => import("./pages/Appointment"));
const Payment = React.lazy(() => import("./pages/Payment"));
const OnBoardingPage = React.lazy(() => import("./pages/OnBoardingPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const RegisterUserPage = React.lazy(() => import("./pages/RegisterUserPage"));
const RegisterPage = React.lazy(() => import("./pages/RegisterPage"));
const ExceptionPage = React.lazy(() => import("./pages/ExceptionPage"));
const ListKamar = React.lazy(() => import("./pages/ListKamar"));
const HomePage = React.lazy(() => import("./pages/HomePage"));
const RoomsPage = React.lazy(() => import("./pages/RoomsPage"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));
const RegisterVaksin = React.lazy(() => import("./pages/RegisterVaksin"));
const NewsPage = React.lazy(() => import("./pages/NewsPage"));
const MCUPage = React.lazy(() => import("./pages/MCUPage"));
const PoliListPage = React.lazy(() => import("./pages/PoliListPage"));
const ProfilePage = React.lazy(() => import("./pages/ProfilePage"));
const HistoryPage = React.lazy(() => import("./pages/HistoryPage"));
const PaketDetailPage = React.lazy(() => import("./pages/PaketDetailPage"));
const AppointmentDetailPage = React.lazy(() =>
  import("./pages/AppointmentDetailPage")
);

const index = (props) => {
  return (
    <>
      <Routes>
        <Route element={<RouteContainer />}>
          <Route exact path="/doctor-home" element={<DocterListPage />} />
          <Route exact path="/home" element={<HomePage />} />
          <Route
            exact
            path="/doctor-detail/:doctorId"
            element={<DetailDoctor />}
          />
          <Route exact path="/make-appointment" element={<Appointment />} />
          <Route exact path="/make-payment" element={<Payment />} />
          <Route
            exact
            path="/appointment-success/:id"
            element={<AppointmentDetailPage />}
          />
          <Route exact path="/on-boarding" element={<OnBoardingPage />} />
          <Route exact path="/" element={<OnBoardingPage />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/register" element={<RegisterPage />} />
          <Route exact path="/register-user" element={<RegisterUserPage />} />
          <Route exact path="/list-room" element={<ListKamar />} />
          <Route exact path="/list-poli" element={<PoliListPage />} />
          <Route exact path="/rooms" element={<RoomsPage />} />
          <Route exact path="/mcu" element={<MCUPage />} />
          <Route
            exact
            path="/package/detail/:id"
            element={<PaketDetailPage />}
          />
          <Route exact path="/register-vaksin" element={<RegisterVaksin />} />
          <Route exact path="/news-page" element={<NewsPage />} />
          <Route exact path="/profile" element={<ProfilePage />} />
          <Route exact path="/history" element={<HistoryPage />} />
        </Route>
        <Route exact path="/exception-handling" element={<ExceptionPage />} />
        <Route exact path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default index;
