import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";

const initialState = {
  vaksin: {
    produkId: "",
    tglKunjungan: "",
  },
  historyAppointment: [],
  historyAppDetail: "",
  appointment: localStorage.getItem("appointment")
    ? JSON.parse(localStorage.getItem("appointment"))
    : {
        layanan: {
          id_poli: "",
          ref_layanan_nama: "",
          disabled: false,
        },
        dokter: {
          peg_id: "",
          nama_dokter: "",
          disabled: false,
        },
        produk: {
          ref_prod_id: "",
          ref_prod_nama: "",
          disabled: false,
        },
        tanggal: "",
        total: 0,
      },
};
const setVaksin = (state, action) => {
  return updateObject(state, {
    vaksin: {
      ...state.vaksin,
      [action.data.key]: action.data.value,
    },
  });
};
const setAppointment = (state, action) => {
  localStorage.setItem(
    "appointment",
    JSON.stringify({
      ...state.appointment,
      ...action.data,
    })
  );
  return updateObject(state, {
    appointment: {
      ...state.appointment,
      ...action.data,
    },
  });
};
const resetAppointment = (state, action) => {
  localStorage.removeItem("appointment");
  return updateObject(state, {
    ...initialState.appointment,
  });
};

const resetVaksin = (state) => {
  return updateObject(state, {
    vaksin: initialState.vaksin,
  });
};
const setHistoryAppointment = (state, action) => {
  return updateObject(state, {
    historyAppointment: action.data,
  });
};
const setHistoryAppDetail = (state, action) => {
  return updateObject(state, {
    historyAppDetail: action.data,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_VAKSIN:
      return setVaksin(state, action);
    case actionTypes.RESET_VAKSIN:
      return resetVaksin(state, action);
    case actionTypes.SET_APPOINTMENT:
      return setAppointment(state, action);
    case actionTypes.RESET_APPOINTMENT:
      return resetAppointment(state, action);
    case actionTypes.SET_HISTORY_APPOINTMENT:
      return setHistoryAppointment(state, action);
    case actionTypes.SET_HISTORY_APPOINTMENT_DETAIL:
      return setHistoryAppDetail(state, action);
    default:
      return state;
  }
};

export default reducer;
