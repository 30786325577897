import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";

const initialState = {
  pendidikan: "",
  agama: "",
  pekerjaan: "",
  jenisIdentitas: "",
  statusNikah: "",
  kamar: "",
  kamarMulti: "",
  produk: "",
  doctorList: "",
  poliList: "",
  doctorDetail: "",
  propinsi: [],
  kotamadya: [],
  kecamatan: [],
  kelurahan: [],
  jadwalDokter: [],
  paketMCU: [],
};
const setPendidikan = (state, action) => {
  return updateObject(state, {
    pendidikan: action.data,
  });
};
const setAgama = (state, action) => {
  return updateObject(state, {
    agama: action.data,
  });
};
const setStatusNikah = (state, action) => {
  return updateObject(state, {
    statusNikah: action.data,
  });
};
const setJenisID = (state, action) => {
  return updateObject(state, {
    jenisIdentitas: action.data,
  });
};
const setPekerjaan = (state, action) => {
  return updateObject(state, {
    pekerjaan: action.data,
  });
};
const setProduk = (state, action) => {
  return updateObject(state, {
    produk: action.data,
  });
};
const setListDoctor = (state, action) => {
  return updateObject(state, {
    doctorList: action.data,
  });
};
const setListPoli = (state, action) => {
  return updateObject(state, {
    poliList: action.data,
  });
};
const setPaketMCU = (state, action) => {
  return updateObject(state, {
    paketMCU: action.data,
  });
};

const setDetailDoctor = (state, action) => {
  let found = state.doctorList.find((item) => item.peg_id === action.data);
  return updateObject(state, {
    detailDoctor: found,
  });
};

const setKamar = (state, action) => {
  return updateObject(state, {
    kamarMulti: action.data.reduce(
      (rows, key, index) =>
        (index % 6 === 0
          ? rows.push([key])
          : rows[rows.length - 1].push(key)) && rows,
      []
    ),
    kamar: action.data,
  });
};

const setPropinsi = (state, action) => {
  return updateObject(state, {
    propinsi: action.data,
  });
};

const setKotamadya = (state, action) => {
  return updateObject(state, {
    kotamadya: action.data,
  });
};

const setKecamatan = (state, action) => {
  return updateObject(state, {
    kecamatan: action.data,
  });
};

const setKelurahan = (state, action) => {
  return updateObject(state, {
    kelurahan: action.data,
  });
};

const setJadwalDokter = (state, action) => {
  return updateObject(state, {
    jadwalDokter: action.data,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PEKERJAAN:
      return setPekerjaan(state, action);
    case actionTypes.SET_PENDIDIKAN:
      return setPendidikan(state, action);
    case actionTypes.SET_AGAMA:
      return setAgama(state, action);
    case actionTypes.SET_STATUS_NIKAH:
      return setStatusNikah(state, action);
    case actionTypes.SET_JENIS_ID:
      return setJenisID(state, action);
    case actionTypes.SET_KAMAR:
      return setKamar(state, action);
    case actionTypes.SET_PRODUK:
      return setProduk(state, action);
    case actionTypes.SET_LIST_DOCTOR:
      return setListDoctor(state, action);
    case actionTypes.SET_LIST_POLI:
      return setListPoli(state, action);
    case actionTypes.SET_DETAIL_DOCTOR:
      return setDetailDoctor(state, action);
    case actionTypes.SET_PROPINSI:
      return setPropinsi(state, action);
    case actionTypes.SET_KOTAMADYA:
      return setKotamadya(state, action);
    case actionTypes.SET_KECAMATAN:
      return setKecamatan(state, action);
    case actionTypes.SET_KELURAHAN:
      return setKelurahan(state, action);
    case actionTypes.SET_JADWAL_DOKTER:
      return setJadwalDokter(state, action);
    case actionTypes.SET_PAKET_MCU:
      return setPaketMCU(state, action);
    default:
      return state;
  }
};

export default reducer;
