// Palette

import { PRI_500 } from "../colors";
const style = {
  styleOverrides: {
    root: {
      // "& label.Mui-focused": {
      //     color: "white",
      // },
      // "& .MuiInput-underline:after": {
      //     borderBottomColor: "yellow",
      // },
      // "& .MuiOutlinedInput-root": {
      //     "& fieldset": {
      //         borderColor: "white",
      //     },
      //     "&:hover fieldset": {
      //         borderColor: "white",
      //     },
      //     "&.Mui-focused fieldset": {
      //         borderColor: "yellow",
      //     },
      // },

      "& .MuiInputLabel-root": {
        color: PRI_500 + "!important",
      },
      "&.Mui-focused fieldset": {
        borderColor: PRI_500 + " !important",
      },
    },
  },
};
export default style;
