import axios from "axios";
import { setLoading } from ".";
import { BASE_URL } from "../../helpers/common/config";
import * as actionTypes from "../actions/types";

export const logout = (actions) => {
  actions && actions();
  return {
    type: actionTypes.LOGOUT,
  };
};
const setAuth = (payload) => {
  return {
    type: actionTypes.SET_AUTH,
    data: {
      token: payload.data.authToken,
      user: payload.data.data_pasien,
    },
  };
};

export const login = (body, action) => {
  const url = BASE_URL + "/api/auth/login-pasien";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body)
      .then((payload) => {
        action && action.success();
        return dispatch(setAuth(payload.data));
      })
      .catch((err) => {
       action &&
         action.failed(
           err.response && err.response.data && err.response.data.message
             ? err.response.data.message
             : "Ada Kesalahan"
         );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const setIdentitas = (payload) => {
  return {
    type: actionTypes.SET_IDENTITAS,
    data: {
      key: payload.key,
      value: payload.value,
    },
  };
};

export const resetIdentitas = () => {
  return {
    type: actionTypes.RESET_IDENTITAS,
  };
};

export const register = (body, action) => {
  const url = BASE_URL + "/api/layanan/daftar-baru";
  // localStorage.setItem("daftarBaru", JSON.stringify(body));
  // return action && action.success && action.success();

  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body)
      .then((payload) => {
        localStorage.setItem("registerAttempt", 0);
        return action && action.success && action.success();
      })
      .catch((err) => {
        localStorage.setItem(
          "registerAttempt",
          localStorage.getItem("registerAttempt")
            ? parseInt(localStorage.getItem("registerAttempt")) + 1
            : 1
        );
        return (
          action &&
          action.success &&
          action.failed(
            err.response && err.response.data
              ? err.response.data.message
              : err.toString() + "Coba gunakan galeri, bukan kamera"
          )
        );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const registerUser = (body, action) => {
  const url = BASE_URL + "/api/auth/daftar-pasien";

  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body)
      .then((payload) => {
        action && action.success();
      })
      .catch((err) => {
        action &&
          action.failed(
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : "Ada Kesalahan"
          );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};
