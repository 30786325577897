export const APP_LOADING = "APP_LOADING";
export const SET_AUTH = "SET_AUTH";
export const SET_BAHASA = "SET_BAHASA";
export const SET_BAHASA_OPEN = "SET_BAHASA_OPEN";

//MASTER
export const SET_PEKERJAAN = "SET_PEKERJAAN";
export const SET_AGAMA = "SET_AGAMA";
export const SET_PENDIDIKAN = "SET_PENDIDIKAN";
export const SET_STATUS_NIKAH = "SET_STATUS_NIKAH";
export const SET_JENIS_ID = "SET_JENIS_ID";
export const SET_KAMAR = "SET_KAMAR";
export const SET_PRODUK = "SET_PRODUK";
export const SET_LIST_DOCTOR = "SET_LIST_DOCTOR";
export const SET_LIST_POLI = "SET_LIST_POLI";
export const SET_DETAIL_DOCTOR = "SET_DETAIL_DOCTOR";
export const SET_PROPINSI = "SET_PROPINSI";
export const SET_KOTAMADYA = "SET_KOTAMADYA";
export const SET_KECAMATAN = "SET_KECAMATAN";
export const SET_KELURAHAN = "SET_KELURAHAN";
export const SET_JADWAL_DOKTER = "SET_JADWAL_DOKTER";
export const SET_PAKET_MCU = "SET_PAKET_MCU";


//AUTH
export const SET_ERROR = "SET_ERROR";
export const SET_LOADING = "SET_LOADING";
export const SET_IDENTITAS = "SET_IDENTITAS";
export const RESET_IDENTITAS = "RESET_IDENTITAS";
export const SET_IDENTITAS_AUTO = "SET_IDENTITAS_AUTO";
export const LOGOUT = "LOGOUT";


//REGISTER
export const SET_VAKSIN = "SET_VAKSIN";
export const RESET_VAKSIN = "RESET_VAKSIN";
export const SET_APPOINTMENT = "SET_APPOINTMENT";
export const RESET_APPOINTMENT = "RESET_APPOINTMENT";
export const SET_HISTORY_APPOINTMENT = "SET_HISTORY_APPOINTMENT";
export const SET_HISTORY_APPOINTMENT_DETAIL = "SET_HISTORY_APPOINTMENT_DETAIL";

