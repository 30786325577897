import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";
import moment from "moment";
const initialState = {
  token: localStorage.getItem("token"),
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "",
  identitas: {
    jenisId: "",
    jenisIdnomor: "",
    namaLengkap: "",
    namaPanggilan: "",
    tempatLahir: "",
    tglLahir: "",
    jenisKelamin: "",
    alamat: "",
    rt: "",
    rw: "",
    kelurahan: "",
    kecamatan: "",
    kotaDati: "",
    kodePos: "",
    propinsi: "",
    noTelpon: "",
    agama: "",
    nilaiYakin: "",
    textNilaiYakin: "",
    statusNikah: "",
    statusWarganegara: "",
    infoIbu: "",
    infoAyah: "",
    infoIstriSuami: "",
    noPenjamin: "",
    namaWaliDarurat: "",
    telponWaliDarurat: "",
    fileUpload: "",
    filePhoto: "",
    // fligFlag: true,
    // inputWaktu: "",
    noTelpon2: "",
    alamatDomisili: "",
    statusPendidikan: "",
    penjamin: "",
    bahasa: "",
    infoKerja: "",
    penjaminSubjenis: 0,
  },
};

const setAuth = (state, action) => {
  localStorage.setItem("token", action.data.token);
  localStorage.setItem("user", JSON.stringify(action.data.user));
  return updateObject(state, {
    token: action.data.token,
    user: action.data.user,
  });
};
const setLogout = (state, action) => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  return updateObject(state, {
    token: "",
    user: "",
  });
};

const setIdentitas = (state, action) => {
  return updateObject(state, {
    identitas: {
      ...state.identitas,
      [action.data.key]: action.data.value,
    },
  });
};

const resetIdentitas = (state, action) => {
  return updateObject(state, {
    identitas: initialState.identitas,
  });
};

const setIdentitasAuto = (state, action) => {
  if (action.data && action.data.NAMA_LGKP) {
    return updateObject(state, {
      identitas: {
        ...state.identitas,
        namaLengkap: action.data.NAMA_LGKP._text,
        jenisKelamin: action.data.JENIS_KLMIN._text,
        tempatLahir: action.data.TMPT_LHR._text,
        tglLahir: moment(
          action.data.TGL_LHR._text,
          "YYYY-MM-DD hh:mm:ss"
        ).format("YYYY-MM-DD"),
        propinsi: action.data.NM_PROP._text,
        kotaDati: action.data.NM_KAB._text,
        kecamatan: action.data.NM_KEC._text,
        kelurahan: action.data.NM_KEL._text,
        alamat: action.data.ALAMAT._text,
        rt: action.data.NO_RT._text,
        rw: action.data.NO_RW._text,
      },
    });
  } else {
    return updateObject(state, {
      identitas: {
        ...state.identitas,
        namaLengkap: "",
        jenisKelamin: "",
        tempatLahir: "",
        tglLahir: "",
        propinsi: "",
        kotaDati: "",
        kecamatan: "",
        kelurahan: "",
        alamat: "",
        rt: "",
        rw: "",
      },
    });
  }
};

// const setDaftarBaru = (state, action) => {
//   return updateObject(state, {
//     daftarBaru: {
//       ...state.daftarBaru,
//       field : action.value
//     }
//   })
// }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH:
      return setAuth(state, action);
    case actionTypes.SET_IDENTITAS:
      return setIdentitas(state, action);
    case actionTypes.RESET_IDENTITAS:
      return resetIdentitas(state, action);
    case actionTypes.SET_IDENTITAS_AUTO:
      return setIdentitasAuto(state, action);
    case actionTypes.LOGOUT:
      return setLogout(state, action);
    default:
      return state;
  }
};

export default reducer;
